import './bootstrap';
import '../css/app.css';

import { createApp, h } from 'vue';
import { createInertiaApp } from '@inertiajs/vue3'
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import { ZiggyVue } from '../../vendor/tightenco/ziggy';

import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';
import WindowHeightPlugin from './shared/Functions/windowHeightPlugin.js';

const appName = window.document.getElementsByTagName('title')[0]?.innerText || 'Elion';
// Définition de la directive tooltip
const tooltipDirective = {
   mounted(el) {
        tippy(el, {
            content: el.getAttribute('title'),
            onShow(instance) {
                el.removeAttribute('title');
            },
            onHide(instance) {
                el.setAttribute('title', instance.props.content);
            },
            theme: 'elion-tooltip'
        });
    },

};

createInertiaApp({
    title: (title) => `${title} - ${appName}`,
    resolve: (name) => resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob('./Pages/**/*.vue')),
    setup({el, App, props, plugin}) {
        const myApp = createApp({render: () => h(App, props)})
            .use(plugin)
            .use(ZiggyVue);
        myApp.config.globalProperties.$filters = {
            formatNumber(value, precision = 2) {
                let number = parseFloat(value).toFixed(precision);
                // Ajouter des séparateurs de milliers
                return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
            }
        }
        myApp.use(WindowHeightPlugin);
        myApp.directive('tooltip', tooltipDirective);
        myApp.mount(el);
        return myApp;
    },
    progress: {
        color: '#4B5563',
    },
}).then(() => {});

